import React from 'react';

import { ContentLayout } from "../layouts/content-layout";
import { DefaultLayout } from "../layouts/default-layout";

import "./404.scss";

export default function NotFoundPage({ children }) {


    return (
        <DefaultLayout title="Shes Mashing It" pageDetails={{ title: "Shes Mashing It" }}>
            <ContentLayout>
                <div className="g-flex__container g-flex__container--column-always u-padding-top--2">
                    <span className="g-flex__item e-fake-heading">Access Denied</span>
                    <span className="u-padding-top--1 g-flex__item">403 Error</span>
                </div>
                {children}
            </ContentLayout>
        </DefaultLayout>
    )
};